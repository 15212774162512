<template>
  <!-- masthead-->
  <section
    id=""
    style=""
    class="page-section"
  >
    <div class="container-fluid">
      <div
        class="row"
        style=""
      >
        <div class="col-12">
          <br>
          <button
            id="ligarWebcam"
            type="button"
            class="btn btn-info"
          >
            <i class="fas fa-video" /> Ligar Webcam
          </button>
          <button
            id="escolherTela"
            type="button"
            class="btn btn-primary"
          >
            <i class="fas fa-desktop" /> Escolher Tela
          </button>
          <button
            id="startGravacao"
            type="button"
            class="btn btn-danger"
          >
            <i class="fas fa-record-vinyl" /> Iniciar Gravação
          </button>
          <button
            id="stopGravacao"
            type="button"
            class="btn btn-secondary"
          >
            <i class="far fa-stop-circle" /> Stop
          </button>
          <button
            id="btSalvar"
            type="button"
            class="btn btn-success"
          >
            <i class="fas fa-download" /> Salvar
          </button>
          <button
            id="btUpload"
            type="button"
            class="btn btn-warning"
          >
            <i class="fa-solid fa-scissors" /> Cortar video
          </button>
          <button
            id="uptela"
            type="button"
            class="btn btn-primary d-none"
          />
          <button
            id="upwebcam"
            type="button"
            class="btn btn-info d-none"
          />
          <button
            id="btTrim"
            type="button"
            class="btn btn-warning d-none"
          >
            <i class="fa-solid fa-scissors" /> Aparar Video
          </button>

          <!--<div class="col-2" id="progressBar">

            </div>-->
        </div>

        <div
          v-if="!showTrimComponent"
          class="col-9"
        >
          <video
            id="blobDaTela"
            class="d-none"
            style="width: 100%;min-width: 100%;min-height: 100%;"
            autoplay
            playsinline
          />
          <video
            id="tela"
            style="width: 100%;min-width: 100%;min-height: 100%;"
            autoplay
            playsinline
            muted
          />
        </div>
        <div
          v-if="!showTrimComponent"
          class="col-3"
        >
          <div class="col-12">
            <video
              id="webcam"
              style="width: 100%;"
              autoplay
              playsinline
              muted
            />
            <video
              id="blobDaCam"
              class="d-none"
              style="width: 100%;"
              autoplay
              playsinline
            />
          </div>

          <div
            id="controls"
            class="col-12 d-none"
          >
            <progress
              id="progress-bar"
              class="col-12"
              min="0"
              max="100"
              value="0"
            >
              0% played
            </progress>

            <div class="col-12 text-center">
              <button
                id="btnPlayPause"
                class="btn btn-info"
                title="play"
                accesskey="P"
              >
                <i class="fa-regular fa-circle-play" />
              </button>
              <button
                id="btnStop"
                class="btn btn-info"
                title="stop"
                accesskey="X"
              >
                <i class="fa-regular fa-circle-stop" />
              </button>
              <button
                id="btnReplay"
                class="btn btn-info"
                title="replay"
                accesskey="R"
              >
                <i class="fa-solid fa-reply-all" />
              </button>
              <button
                id="btnFullScreen"
                class="btn btn-info"
                title="toggle full screen"
                accesskey="T"
              >
                [&nbsp;&nbsp;]
              </button>
              <button
                id="btnMute"
                class="btn btn-info"
                title="mute"
              >
                <i class="fa-solid fa-volume-xmark" />
              </button>
              <input
                id="volume-bar"
                style="width:50%"
                type="range"
                title="volume"
                min="0"
                max="1"
                step="0.1"
                value="1"
              >
            </div>
          </div>
        </div>
        <div
          v-if="!showTrimComponent"
          id="errorMsg"
          class="col-12"
        />

        <div v-else>
          <TrimVideo
            :video-src="videoSrc"
            :video-cam-src="videoCamSrc"
            :video-file="videoFile"
            :screen="screenVideo"
            :webcam="webcamVideo"
          />
        </div>
      </div><!--div row-->
    </div>
    <!-- turn on cam -->
    <b-modal
      id="modal-center"
      ref="modal-center"
      centered
      title="Gravação de aulas"
      hide-footer
      ok-title="Entendi"
    >
      <h4>Como funciona?</h4>
      <b-card-text>
        Para ativar a gravação de aulas, autorize o acesso do navegador ao microfone e sua webcam.
      </b-card-text>
      <b-button
        class="mt-2"
        variant="primary"
        block
        @click="confirm()"
      >
        Entendi
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import { loadScript } from 'vue-plugin-load-script'
// import Trim from '../../js/player-trim'
// import Nouislider from '../../js/nouislider'
import Player from '../../js/player'
import TrimVideo from './TrimVideo.vue'

export default {
  components: {
    TrimVideo
  },
  data() {
    return {
      mediaRecorder: null,
      mediaRecorderCam: null,
      ligarWebcam: '',
      escolherTela: '',
      startGravacao: '',
      btSalvar: '',
      btUpload: '',
      stopGravacao: '',
      blobDaTela: '',
      blobDaCam: '',
      uptela: '',
      upwebcam: '',
      tela: '',
      webcam: '',
      // Trim: Trim(),
      // Nouislider: Nouislider(),
      showTrimComponent: false,
      videoSrc: '',
      videoCamSrc: '',
      videoFile: '',
      screenVideo: '',
      webcamVideo: '',
    }
  },
  mounted() {
    const refenceThis = this
    this.ligarWebcam = document.getElementById('ligarWebcam')
    this.escolherTela = document.getElementById('escolherTela')
    this.startGravacao = document.getElementById('startGravacao')
    this.btSalvar = document.getElementById('btSalvar')
    this.btUpload = document.getElementById('btUpload')
    this.stopGravacao = document.getElementById('stopGravacao')
    this.blobDaTela = document.getElementById('blobDaTela')
    this.blobDaCam = document.getElementById('blobDaCam')
    this.uptela = document.getElementById('uptela')
    this.upwebcam = document.getElementById('upwebcam')
    this.tela = document.getElementById('tela')
    this.webcam = document.getElementById('webcam')
    this.btTrim = document.getElementById('btTrim')
    this.controls = document.getElementById('controls')

    this.escolherTela.disabled = true
    this.startGravacao.disabled = true
    this.btSalvar.disabled = true
    this.stopGravacao.disabled = true
    this.btUpload.disabled = true
    this.btTrim.disabled = true

    this.ligarWebcam.addEventListener('click', async () => {
      const streamweb = await this.capturarWebcam()
      const mimeType = 'video/mp4;codecs=h264,opus'

      this.webcam.srcObject = streamweb
      this.ligarWebcam.disabled = true
      this.escolherTela.disabled = false
      this.ligarWebcam.classList.replace('btn-info', 'btn-outline-secondary')

      this.startGravacao.addEventListener('click', () => {
        this.mediaRecorderCam = this.createRecorderCam(streamweb, mimeType)
      })
    })

    this.escolherTela.addEventListener('click', async () => {
      const stream = await this.capturarTela()
      const mimeType = 'video/mp4;codecs=h264,opus'

      this.tela.srcObject = stream
      this.escolherTela.disabled = true
      this.startGravacao.disabled = false
      this.escolherTela.classList.replace('btn-primary', 'btn-outline-secondary')

      this.startGravacao.addEventListener('click', () => {
        refenceThis.createRecorder(stream, mimeType)
        refenceThis.stopGravacao.disabled = false
        refenceThis.disabled = true
        this.startGravacao.classList.replace('btn-danger', 'btn-outline-danger')
        this.startGravacao.innerHTML = '<div class="spinner-grow spinner-grow-sm text-danger" role="status"></div> GRAVANDO'
      })
    })

    this.stopGravacao.addEventListener('click', async function () {
      const tracksTela = refenceThis.tela.srcObject.getTracks()
      const tracksCam = refenceThis.webcam.srcObject.getTracks()
      tracksTela.forEach(track => track.stop())
      tracksCam.forEach(track => track.stop())

      refenceThis.mediaRecorder.stop()
      refenceThis.mediaRecorderCam.stop()

      refenceThis.blobDaCam.classList.remove('d-none')
      refenceThis.webcam.classList.add('d-none')

      refenceThis.blobDaTela.classList.remove('d-none')
      refenceThis.tela.classList.add('d-none')

      refenceThis.controls.classList.remove('d-none')

      refenceThis.startGravacao.innerHTML = '<i class="fas fa-record-vinyl"></i> GRAVADO'
      this.disabled = true
      this.classList.replace('btn-secondary', 'btn-outline-secondary')
    })
    // // eslint-disable-next-line no-unused-expressions
    // this.Trim
    // eslint-disable-next-line no-unused-expressions
    // this.Nouislider
    loadScript('https://use.fontawesome.com/releases/v6.0.0/js/all.js')
  },
  methods: {
    async capturarTela() {
      // eslint-disable-next-line no-return-await
      return await navigator.mediaDevices.getDisplayMedia({
        audio: true,
        video: { mediaSource: 'screen' }
      })
    },
    async capturarWebcam() {
    // eslint-disable-next-line no-return-await
      return await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true
      })
    },
    createRecorder(stream, mimeType) {
      const ref = this
      let recordedChunks = []

      this.mediaRecorder = new MediaRecorder(stream)

      this.mediaRecorder.ondataavailable = function (e) {
        if (e.data.size > 0) {
          recordedChunks.push(e.data)
        }
      }
      this.mediaRecorder.onstop = function () {
        ref.salvarArquivos(recordedChunks, 'tela')
        ref.previewTela(recordedChunks)
        ref.uploadArquivos(recordedChunks, 'tela')

        Player()
        recordedChunks = []
      }
      this.mediaRecorder.start(200)
      this.screenVideo = this.mediaRecorder
      return this.mediaRecorder
    },
    createRecorderCam(streamweb, mimeType) {
      let recordedChunksCam = []
      const ref = this

      const mediaRecorderCam = new MediaRecorder(streamweb)

      mediaRecorderCam.ondataavailable = function (e) {
        if (e.data.size > 0) {
          recordedChunksCam.push(e.data)
        }
      }
      mediaRecorderCam.onstop = async () => {
        await ref.salvarArquivos(recordedChunksCam, 'webcam')
        await ref.previewCam(recordedChunksCam)
        await ref.uploadArquivos(recordedChunksCam, 'webcam')

        recordedChunksCam = []
      }
      mediaRecorderCam.start(200)

      return mediaRecorderCam
    },
    previewTela(recordedChunks) {
      const blob = new Blob(recordedChunks, { type: 'video/mp4;codecs=h264,opus' })
      const blobDaTela = document.getElementById('blobDaTela')

      blobDaTela.src = URL.createObjectURL(blob)
      this.screenVideo = new File([blob], 'screen-video')
      this.videoSrc = blob
    },
    previewCam(recordedChunks) {
      const blob = new Blob(recordedChunks, { type: 'video/mp4;codecs=h264,opus' })
      const blobDaCam = document.getElementById('blobDaCam')

      blobDaCam.src = URL.createObjectURL(blob)
      this.webcamVideo = new File([blob], 'webcam-video')
      this.videoCamSrc = URL.createObjectURL(blob)
    },
    salvarArquivos(recordedChunks, tipo) {
      this.btSalvar.disabled = false

      this.btSalvar.addEventListener('click', () => {
        const timeElapsed = Date.now()
        const today = new Date(timeElapsed)
        const blob = new Blob(recordedChunks, { type: 'video/mp4;codecs=h264,opus' })

        const datefile = `${tipo}-${today.toISOString()}`

        const arquivo = `${tipo} - ${datefile}`

        const downloadArquivo = document.createElement('a')

        downloadArquivo.href = URL.createObjectURL(blob)
        downloadArquivo.download = `${arquivo}.mp4`
        document.body.appendChild(downloadArquivo)
        // downloadArquivo.click()
        URL.revokeObjectURL(blob)
        document.body.removeChild(downloadArquivo)

        if (tipo === 'tela') {
          this.videoFile = blob
          console.log(this.videoFile)
        }

        this.btSalvar.disabled = true
        this.btSalvar.innerHTML = '<i class="fas fa-download"></i> ARQUIVOS SALVOS'
        this.btSalvar.classList.replace('btn-success', 'btn-outline-success')
        this.btUpload.disabled = false

        return `tela - ${datefile}`
      })
    },
    uploadArquivos(recordedChunks, tipo) {
      const ref = this
      const timeElapsed = Date.now()
      const today = new Date(timeElapsed)
      const file = new Blob(recordedChunks, { type: 'video/mp4;codecs=h264,opus' })
      const filename = `${tipo}-${today.toISOString()}`
      const progresso = document.getElementById('progressBar')

      const lenght = 1024 * 1024 * 2
      const totalSize = file.size
      const start = 0
      const end = start + lenght
      const fd = null
      const blob = null
      const xhr = null

      this.btUpload.addEventListener('click', async () => {
        this.btUpload.disabled = true
        this.btUpload.classList.add('d-none')
        ref.showTrimComponent = true
      })
    }
  }
}
</script>

<style scoped>
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
}
.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}
.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat;
}
/* Offset direction
 */
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto;
}
/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  top: -100%;
  width: 0;
}
.noUi-horizontal .noUi-origin {
  height: 0;
}
.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}
.noUi-touch-area {
  height: 100%;
  width: 100%;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 18px;
}
.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  right: -17px;
  top: -6px;
}
.noUi-vertical {
  width: 18px;
}
.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  right: -6px;
  bottom: -17px;
}
.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto;
}
/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
}
.noUi-connects {
  border-radius: 3px;
}
.noUi-connect {
  background: #3FB8AF;
}
/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}
.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
}
.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
}
/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px;
}
.noUi-handle:after {
  left: 17px;
}
.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}
.noUi-vertical .noUi-handle:after {
  top: 17px;
}
/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8;
}
[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}
/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-pips {
  position: absolute;
  color: #999;
}
/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}
.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}
/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC;
}
.noUi-marker-sub {
  background: #AAA;
}
.noUi-marker-large {
  background: #AAA;
}
/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}
.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}
.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}
.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}
/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}
.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px;
}
.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}
.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}
.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}
.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}
.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}
.noUi-horizontal .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
  left: auto;
  bottom: 10px;
}
.noUi-vertical .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(0, -18px);
  transform: translate(0, -18px);
  top: auto;
  right: 28px;
}

</style>
